<template>
  <div>
    <div class="nurseOffice mt10">
      <div class="mt10 mb-3">
        <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{ $t('message.hospital') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('message.schedule_cabinet') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-loading="loadingData">
        <filterSchedules />
        <schedulesCabinetFull @loadOn="loadOn()" @loadOff="loadOff()" ref="schedule" />
      </div>
    </div>
  </div>
</template>
<script>
import schedulesCabinetFull from "./componentsReadOnly/schedulesCabinetFull";
import filterSchedules from "./componentsReadOnly/filter-schedules";
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "schedulesCabinet",
  components: { schedulesCabinetFull, filterSchedules },
  data() {
    return {
      loadingData: false,
    }
  },
  mounted() {
    if (this.bunk.length != 0) {
      this.$refs.schedule.scroll()
    } else {
      this.$refs.schedule.fetchAgain();
    }
  },
  computed: {
    ...mapGetters({
      bunk: 'schedulesCabinets/bunk'
    })
  },
  methods: {
    ...mapActions({
      empty: 'schedulesCabinets/empty',
      delete: 'schedulesCabinets/destroy'
    }),
    afterOpen() {
    },
    loadOn() {
      this.loadingData = true
    },
    loadOff() {
      this.loadingData = false
    },
    afterClosed() {
    },
  },

};
</script>
